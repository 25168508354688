body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* HEADER */
.mainNav {
  height: 50px;
  margin-bottom: 20px;
  background-color: #ddd !important;
}
.loggedUser {
  font-size: 10px;
  display: inline-block;
  margin-right: 10px;
}

table {
  width: 100%;
  border: 1px solid #ddd;
}

.json_to_html_table {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 3px;
  word-break: break-all;
}

.method-box{
  margin-bottom: 10px;
  border-radius: 0 !important;
  color: #406090;
  background-color: #faffff !important;
  border-color: #ccddff !important;
}

.method-box .card-header {
  cursor: pointer;
  padding: .25rem .75rem;
  border-color: #ccddff !important;
}

.method-box .card-body {
  padding: .75rem;
}

.method-box .error {
  background-color: #ffeeee;
  color: #bb1111;
}

.center-text {
  text-align: center;
}


/* LOBA */

.contractCard {
  text-align: center;
}
